let url = "";

if (process.env.NODE_ENV === 'development') {
	url = process.env.REACT_APP_URL;
} else {
	url = "https://api.ampg.laonlabs.com";
}

export const Constants = {
	url: url,
	baseUrl: url + "/api/admin",
};
