export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Associados",
				root: true,
				icon: "fas fa-users",
				page: "associado",
				bullet: "dot"
			},
			{
				title: "Lotes",
				root: true,
				icon: "fas fa-list",
				page: "lote",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				icon: "fas fa-chart-pie",
				bullet: "dot",
				submenu: [
					{
						title: "Relatório de Lotes",
						bullet: "dot",
						page: "relatorio/01"
					}
				]
			},
			{
				title: "Acessos",
				root: true,
				icon: "fas fa-user-shield",
				bullet: "dot",
				submenu: [
					{
						title: "Administradores",
						page: "admin",
						bullet: "dot"
					},
					{
						title: "Grupos de Acesso",
						page: "group",
						bullet: "dot"
					},
				]
			}
		]
	}
};
